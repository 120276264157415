'use client'

import React, { useEffect } from 'react'

import * as Sentry from '@sentry/nextjs'
import NextError from 'next/error'

/**
 * A global error component, this should never be seen in practice as error.tsx will take precedence, but this will show
 * up if we have errors in the root layout.
 */
export default function GlobalError({ error }: { error: Error & { digest?: string } }) {
  useEffect(() => {
    Sentry.captureException(error)
  }, [error])

  return (
    <html lang="en">
      <body>
        <NextError statusCode={500} />
      </body>
    </html>
  )
}
